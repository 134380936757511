import Event from "events";

export enum EOpeningState {
	CLOSED = "closed",
	OPENED = "opened",
}

class EventEmitter extends Event {}

export default class SideMenuStatus {
	protected static ctx: SideMenuStatus;

	private _status: EOpeningState =
		(localStorage.getItem("side-menu-status") as EOpeningState) ?? EOpeningState.CLOSED;
	private readonly event = new EventEmitter();

	constructor() {
		if (SideMenuStatus.ctx) return SideMenuStatus.ctx;
		SideMenuStatus.ctx = this;

		this.switch(this.status);

		return SideMenuStatus.ctx;
	}

	public static getInstance() {
		if (!SideMenuStatus.ctx) new this();
		return SideMenuStatus.ctx;
	}

	public get status() {
		return this._status;
	}

	/**
	 * @returns removelistener callback
	 */
	public onSwitch(callback: (type: EOpeningState) => void) {
		this.event.on("switch-side-menu", callback);
		return () => {
			this.event.off("switch-side-menu", callback);
		};
	}

	public toggle() {
		if (this.status === EOpeningState.CLOSED) {
			this.switch(EOpeningState.OPENED);
			return EOpeningState.OPENED;
		}
		this.switch(EOpeningState.CLOSED);
		return EOpeningState.CLOSED;
	}

	public close() {
		this.switch(EOpeningState.CLOSED);
	}

	private switch(type: EOpeningState) {
		if (type === this.status) return;
		this._status = type;
		localStorage.setItem("side-menu-state", this._status);
		this.event.emit("switch-side-menu", this._status);
	}
}
