import { BigNumber } from "ethers";
import EventEmitter from "events";

import EthBigNumber from "Services/Wallet/EthBigNumber";
import Web3ModalWallet, { IWallet as IWeb3Wallet } from "Services/Wallet/Web3ModalWallet";

export interface IWallet {
	userAddress: string | null;
	balance: EthBigNumber | null;
	chainId: number | null;
}

export default class Wallet {
	protected static ctx: Wallet;
	private readonly event = new EventEmitter();
	protected web3ModalWallet: Web3ModalWallet | null = null;

	public get walletData() {
		let balance = Wallet.bigNumberToEthBigNumber(this.web3ModalWallet?.web3WalletData?.balance ?? null);
		return {
			userAddress: this.web3ModalWallet?.web3WalletData?.userAddress ?? null,
			chainId: this.web3ModalWallet?.web3WalletData?.chainId ?? null,
			balance,
		};
	}

	private constructor() {
		Wallet.ctx = this;
		if (localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER")) {
			this.connect();
		}
	}

	public static getInstance() {
		if (!Wallet.ctx) new this();
		return Wallet.ctx;
	}

	public onChange(callback: (walletData: IWallet) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}

	public async connect() {
		try {
			this.web3ModalWallet = await Web3ModalWallet.getInstance().connect();

			this.web3ModalWallet.onChange((web3Event) => this.changed(web3Event));
			this.changed({
				userAddress: this.walletData?.userAddress,
				chainId: this.walletData?.chainId,
				balance: this.walletData?.balance?.getBigNumber() ?? null,
			});
		} catch (e) {
			console.warn(e);
		}
	}

	public disconnect() {
		Web3ModalWallet.getInstance().disconnect();
	}

	private async changed(web3Wallet: IWeb3Wallet | null) {
		let balance = Wallet.bigNumberToEthBigNumber(web3Wallet?.balance ?? null);
		const walletData: IWallet = {
			userAddress: web3Wallet?.userAddress ?? null,
			chainId: web3Wallet?.chainId ?? null,
			balance,
		};
		this.event.emit("change", walletData);
	}

	private static bigNumberToEthBigNumber(bigNumber: BigNumber | null): EthBigNumber | null {
		let ethBigNumber: EthBigNumber | null = null;
		if (bigNumber) {
			ethBigNumber = new EthBigNumber(bigNumber);
		}
		return ethBigNumber;
	}
}
