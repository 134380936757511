import React from "react";
import classes from "./classes.module.scss";

type IProps = {
	subtitle?: string
	color?: "1"|"2"
}
export default class BigTitle extends React.Component<IProps> {
	static defaultProps: IProps = {
		color: "1",
	};

	public render(): JSX.Element {
		return (
			<div className={classes["root"]} {...this.props}>
				{this.props.children}
				{this.props.subtitle && <div className={classes["subtitle"]}>{this.props.subtitle}</div>}
			</div>
		);
	}
}
