import React from "react";

import classes from "./classes.module.scss";

type IProps = {
	img: number;
	title: string;
	socials: string;
	color: 1|2|3;
};

type IState = {
	image: any;
	hovering: boolean;
};
export default class ArtworkHover extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			image: "",
			hovering: false
		};
		this.loadImage();
	}

	loadImage = () => {
		import(`../artworks/${this.props.img}.png`).then((image) => {
			this.setState({
				image,
			});
		});
	};

	public render(): JSX.Element {
		console.log(this.state)
		return (
			<div
				className={classes["root"]}
				onMouseEnter={() =>
					this.setState({
						hovering: true,
					})
				}
				onMouseLeave={() =>
					this.setState({
						hovering: false,
					})
				}
			>
				<img src={this.state.image.default} alt="" />
				<div className={classes["hover"]} data-hovering={this.state.hovering} data-color={this.props.color}>
					<div className={classes["title"]}>{this.props.title}</div>
					<div className={classes["socials"]}>{this.props.socials}</div>
				</div>
			</div>
		);
	}
}
