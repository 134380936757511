import React from "react";
import Dropdown from "../Dropdown";

import classes from "./classes.module.scss";
import Title from "Components/Elements/Title";

export type IDropdownContent = {
	title: string;
	content: string | JSX.Element;
}

type IProps = {
	dropdownContent: IDropdownContent[]
};


export default class DropdownContainer extends React.Component<IProps> {
	private closabled: (() => void)[] = [];

	public render(): JSX.Element {
		return (
			<div className={classes["root"]}>
				<div className={classes["title"]}><Title>FAQ</Title></div>
				{this.props.dropdownContent.map((dropdown, i) =>
					<Dropdown closeAction={(closabled) => this.closabled.push(closabled)}
						onOpen={(isOpen) => this.onOpen(isOpen)}
						key={i} title={dropdown.title}
						content={dropdown.content} />)}
			</div>
		);
	}

	private onOpen(isOpen: boolean) {
		this.closeAll();
	}

	private closeAll() {
		this.closabled.forEach((close) => {
			close();
		})
	}
}
