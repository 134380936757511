import React from "react";

import Text from "Components/Elements/Text";
import Title from "Components/Elements/Title";

import classes from "../classes.module.scss";
import SummoningCrystal from "./SummoningCrystal";

type IProps = {};
type IState = {};

export default class EightSummonerTribesSection extends React.Component<IProps, IState> {
	public render(): JSX.Element {
		return (
			<div className={classes["eight-summoner-tribes-section"]}>
                <div className={classes["eight-summoner-tribes-container"]}>
                    <div className={classes["eight-summoner-tribes-content"]}>
                        <Title>8 Summoner tribes</Title>
                        <div className={classes["text-container"]}>
                            <Text>
                                Summoners come from 8 different tribes: humans, elves, dragons, demons, beasts,
                                dolls, mermaids, and flames. 
                                <br/>
                                Together, they combine their powers to protect the
                                world.
                            </Text>
                        </div>
                    </div>
                </div>
                <SummoningCrystal/>
            </div>
		);
	}

}
