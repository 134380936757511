import React from "react";
import classes from "./classes.module.scss";

type IProps = {}
export default class Bold extends React.Component<IProps> {
	public render(): JSX.Element {
		return (
			<span className={classes["root"]}>
				{this.props.children}
			</span>
		);
	}
}
