import classes from "./classes.module.scss";

import RightArrow from "../images/right-arrow.svg";
import BleblePicture from "../images/bleble.png";
import BackgroundCharacters from "../images/background-characters.png";
import SmallBar from "../images/small-bar.png";
export default function Artist() {
	return (
		<div className={classes["root"]}>
			<img
				src={BackgroundCharacters}
				alt="アーティスト written in background"
				className={classes["background-characters"]}
			/>
			<div className={classes["artist-left"]}>
				<div className={classes["artist-title"]}>Artist</div>
				<div className={classes["artist-subtitle"]}>
					Bleble158
					<div className={classes["orange-bar"]} />
				</div>
				<div className={classes["artist-text"]}>
					BleBle is an NFT artist with a passion for art; from animated movies, anime, games and crypto. He
					works as a concept artist working in the animation industry for over 6 years. He previously worked
					at The Monk Studio, Earthshine Studio, Igloo Studio and many more.
					<br />
					<br />
					His artstyle is colorful and unique, with inspiration from many Japanese anime. He devoted his
					paintbrush to share stories in a colorful artstyle to create an all new collectible game, awakening
					the Waifu Summoners to the crypto world.
				</div>
				<div
					className={classes["artist-foundation"]}
					onClick={() => window.open("https://foundation.app/@BleBle158", "_blank")?.focus()}
				>
					FOUNDATION&nbsp;PROFIL
					<img src={RightArrow} alt="" />
				</div>
			</div>
			<div className={classes["artist-right"]}>
				<img src={BleblePicture} alt="" className={classes["bleble-picture"]} />
				<img src={SmallBar} alt="" className={classes["small-bar"]} />
			</div>
		</div>
	);
}
