import BigTitle from "Components/Elements/BigTitle";
import TeamSection from "Components/Pages/Home/Sections/TeamSection";
import DefaultTemplate from "Components/PageTemplates/DefaultTemplate";

import BasePage from "../Base";
import Artist from "./Artist";
import classes from "./classes.module.scss";
import FocusArtworks from "./FocusArtworks";
import Suwas from './images/suwas.png';

export default class Team extends BasePage {
	public render(): JSX.Element {
		return (
			<DefaultTemplate>
				<div className={classes["root"]}>
					<div className={classes["background"]} />
					<BigTitle subtitle="チーム">TEAM</BigTitle>
					<Artist />
					<FocusArtworks />
					<div className={classes["team-section-container"]}>
						<TeamSection withButton={false}/>
					</div>
					<div className={classes["suwas-container"]}><img src={Suwas} alt="" /></div>

				</div>
			</DefaultTemplate>
		);
	}
}





