import React from "react";

import classes from "./classes.module.scss";

type IProps = {}
export default class Title extends React.Component<IProps> {
	public render(): JSX.Element {
		return (
			<h1 className={classes["root"]}>
				{this.props.children}
			</h1>
		);
	}
}
