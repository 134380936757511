import ArtworkHover from "./ArtworkHover";
import classes from "./classes.module.scss";

export default function FocusArtworks() {
	return (
		<div className={classes["root"]}>
			<div className={classes["subtitle"]}>Focus artworks</div>
			<div className={classes["artworks-container"]}>
				<div className={classes["artworks-first-line"]}>
					<ArtworkHover img={1} title="Red Yokai" socials="@komorebi88" color={1} />
					<ArtworkHover img={2} title="Lina <Streetkid>" socials="@BleBle158" color={1} />
					<ArtworkHover img={3} title="Tokyo - 東京" socials="@Kiwi" color={1} />
				</div>
				<div className={classes["artworks-second-line"]}>
					<ArtworkHover img={4} title="lycan<Nomad >" socials="@BleBle158" color={2} />
					<ArtworkHover img={5} title="Miami Beach" socials="@hitsu" color={2} />
					<ArtworkHover img={6} title='HoloTale "Raijin"' socials="@KingCozz" color={2} />
					<ArtworkHover img={7} title="Yak Sa<Nomad>" socials="@BleBle158" color={2} />
				</div>
				<div className={classes["artworks-third-line"]}>
					<ArtworkHover img={8} title="Bangkok - กรุงเทพ" socials="@BleBle158" color={3} />
					<ArtworkHover img={9} title='HoloTale "Fujin"' socials="@frenchy" color={3} />
					<ArtworkHover img={10} title="Kitsune Yokai" socials="@hitsu" color={3} />
				</div>
			</div>
			<div className={classes["artworks-container-mobile"]}>
				<div className={classes["artworks-one"]}>
					<ArtworkHover img={1} title="Red Yokai" socials="@komorebi88" color={1} />
				</div>
				<div className={classes["artworks-two"]}>
					<ArtworkHover img={2} title="Lina <Streetkid>" socials="@BleBle158" color={1} />
					<ArtworkHover img={4} title="lycan<Nomad >" socials="@BleBle158" color={1} />
				</div>
				<div className={classes["artworks-one"]}>
					<ArtworkHover img={3} title="Tokyo - 東京" socials="@Kiwi" color={2} />
				</div>
				<div className={classes["artworks-two"]}>
					<ArtworkHover img={6} title='HoloTale "Raijin"' socials="@KingCozz" color={2} />
					<ArtworkHover img={9} title='HoloTale "Fujin"' socials="@frenchy" color={2} />
				</div>
				<div className={classes["artworks-one"]}>
					<ArtworkHover img={10} title="Kitsune Yokai" socials="@hitsu" color={3} />
				</div>
			</div>
		</div>
	);
}
