import React from "react";
import { Link } from "react-router-dom";

import profileArbiar from "assets/images/pages/home/profile-arbiar.png"
import profileBleble from "assets/images/pages/home/profile-bleble.png"
import profileBoobilion from "assets/images/pages/home/profile-boobilion.png"
import profileCaptain from "assets/images/pages/home/profile-captain.png"
import profileFlash from "assets/images/pages/home/profile-flash.png"
import profileFrenchy from "assets/images/pages/home/profile-frenchy.png"
import Text from "Components/Elements/Text";
import Title from "Components/Elements/Title";

import classes from "../classes.module.scss";

type IProps = {
    withButton: boolean
};
type IState = {};

export default class TeamSection extends React.Component<IProps, IState> {
	public render(): JSX.Element {
		return (
			<div className={classes["team-section"]}>
				<Title>Team</Title>
				<div className={classes["team-text"]}>
					<Text>The team that made the summoning possible</Text>
				</div>
				<div className={classes["team-members"]}>
					{this.teamPerson("Bleble", "Artist", profileBleble)}
					{this.teamPerson("Frenchy", "Blockchain developer", profileFrenchy)}
					{this.teamPerson("Flash", "Project manager", profileFlash)}
					{this.teamPerson("Captain Lo", "UX/UI designer", profileCaptain)}
					{this.teamPerson("Arbiar", "Lore expert", profileArbiar)}
					{this.teamPerson("Boobilion", "Gamification expert", profileBoobilion)}
				</div>
				{this.props.withButton && (
					<div className={classes["button-tribe-container"]}>
						<Link
							to="/team"
							className={classes["button-tribe"]}
						>
							More about our tribe
						</Link>
					</div>
				)}
			</div>
		);
	}

    private teamPerson(name:string, job:string, profilPicture:string): JSX.Element {
        return (
            <div className={classes["team-person"]}>
                <img alt="profilePicture" src={profilPicture} className={classes["profile-picture"]}/>
                <h2 className={classes["person-name"]}>{name}</h2>
                <h3 className={classes["person-job"]}>{job}</h3>
            </div>
        )
    }
}
