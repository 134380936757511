import React, { useEffect } from "react";
import { Navigate, Outlet, Route, HashRouter as Router, Routes, useLocation } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";

import SideMenuStatus from "Stores/SideMenuStatus";

import Home from "./Pages/Home";
import Roadmap from "./Pages/Roadmap";
import Story from "./Pages/Story";
import Team from "./Pages/Team";

type IProps = {};

export default class Main extends React.Component<IProps> {

	private removeSideMenuOnSwitch = () => { };

	public render(): JSX.Element {
		return (
			<ParallaxProvider>
				<Router basename="/">
					<Routes>
						<Route element={<BindRouter />}>
							<Route element={<Home />} path="/" />
							<Route element={<Story />} path="/story" />
							<Route element={<Team />} path="/team" />
							<Route element={<Roadmap />} path="/roadmap" />
							<Route element={<Navigate to="/" replace />} path="*" />
						</Route>
					</Routes>
				</Router>
			</ParallaxProvider>
		);
	}

	private updateSideMenuStatus() {
		document.body.setAttribute("side-menu-status", SideMenuStatus.getInstance().status);
	}

	public componentDidMount() {
		this.removeSideMenuOnSwitch = SideMenuStatus.getInstance().onSwitch((type) => {
			this.updateSideMenuStatus();
		});
	}

	public componentWillUnmount() {
		this.removeSideMenuOnSwitch();
	}
}


function BindRouter() {
	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
		SideMenuStatus.getInstance().close();
		document.body.setAttribute("route", location.pathname);
	}, [location.pathname]);

	return <Outlet />;
}
