import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as SuwaLogo } from "assets/images/logos/logo2.svg";
import { ReactComponent as MediumLogo } from "assets/images/logos/medium.svg";
import { ReactComponent as TwitterLogo } from "assets/images/logos/twitter.svg";

import classes from "./classes.module.scss";

export default class Home extends React.Component {
	public render(): JSX.Element | null {
		return (
			<footer className={classes["root"]}>
				<div className={classes["desktop"]}>
					<div className={classes["content"]}>
						<div className={classes["segments"]}>
							<div className={classes["segments-padding"]}>
								<div className={[classes["segment"], classes["segment-left"]].join(" ")}>
									<Link className={classes["logo"]} to="/">
										<SuwaLogo />
									</Link>
								</div>
								<div className={[classes["segment"], classes["segment-middle"]].join(" ")}>
									<Link className={classes["route"]} to="/">
										Home
									</Link>
									<Link className={classes["route"]} to="/story">
										Story
									</Link>
									<Link className={classes["route"]} to="/team">
										Team
									</Link>
									<Link className={classes["route"]} to="/roadmap">
										Roadmap
									</Link>
								</div>
								<div className={[classes["segment"], classes["segment-right"]].join(" ")}>
									<Link to="/">
										<TwitterLogo />
									</Link>
									<Link to="/">
										<MediumLogo />
									</Link>
								</div>
							</div>
						</div>
					</div>
					<div className={classes["copyright"]}>© WORLDOFSUWA. All rights reserved.</div>
				</div>
				<div className={classes["mobile"]}>
					<div className={classes["content-mobile"]}>
						<div className={classes["logo-mobile"]}>
							<Link className={classes["logo"]} to="/">
								<SuwaLogo />
							</Link>
						</div>
						<div className={[classes["routes-mobile"], classes["left"]].join(" ")}>
							<Link className={classes["route"]} to="/">
								Home
							</Link>
							<Link className={classes["route"]} to="/roadmap">
								Roadmap
							</Link>
							<div >
								<Link className={classes["twitter"]} to="/">
									<TwitterLogo />
								</Link>
								<Link to="/">
									<MediumLogo />
								</Link>
							</div>
						</div>
						<div className={[classes["routes-mobile"], classes["right"]].join(" ")}>
							<Link className={classes["route"]} to="/story">
								The Story
							</Link>
							<Link className={classes["route"]} to="/team">
								Team
							</Link>
						</div>
					</div>
					<div className={classes["border-mobile"]} />
					<div className={classes["copyright"]}>© WORLDOFSUWA. All rights reserved.</div>
				</div>
			</footer >
		)

	}
}
