import React from "react";

import JourneyBackgroundDesktop from "assets/images/pages/home/background1-start-journey.png";
import JourneyBackgroundMobile from "assets/images/pages/home/background3-start-journey.png";
import Text from "Components/Elements/Text";
import Title from "Components/Elements/Title";

import classes from "../classes.module.scss";

type IProps = {};
type IState = {};

export default class StartJourneySection extends React.Component<IProps, IState> {
	public render(): JSX.Element {
		return (
			<div className={classes["journey-section"]}>
				<img
					src={JourneyBackgroundDesktop}
					alt=""
					className={[classes["journey-background-desktop"], classes["journey-background"]].join(" ")}
				/>
				<img
					src={JourneyBackgroundMobile}
					alt=""
					className={[classes["journey-background-mobile"], classes["journey-background"]].join(" ")}
				/>
				<div className={classes["journey-section-container"]}>
					<Title>Become a Summoner and start your journey</Title>
					<Text>
						1000 years have passed since the Cataclysm. A great war that divided the world of the Living
						against the Shadows - Evil creatures that destroyed everything in their path because they
						refused the balance between the different tribes.
						<br />
						<br />
						To save their world from the forces of evil, 5,000 women were chosen by Celestial Beings.
						<br />
						<br />
						Today, we call them The Summoner Warriors.
						<br />
						<br />
						These summoners are inclusive of all tribes, ages & backgrounds who are yet to discover the
						power of fighting against the shadows.
						<br />
						<br /> Will they be up for the next challenge that awaits them?
					</Text>
				</div>
			</div>
		);
	}

}
