import React from "react";

import Title from "Components/Elements/Title";

import classes from "../classes.module.scss";

type IProps = {};
type IState = {};

export default class RoadmapSection extends React.Component<IProps, IState> {

	public render(): JSX.Element {
		return (
            <div className={classes["roadmap-section"]}>
                <div className={classes["roadmap-container"]}>
                    <Title>Roadmap</Title>
                    <div className={classes["roadmap-left-align"]}>
                        {this.content("The Gathering", "Public-sale", "The minting ceremony for the Crystal Generation Event will start, requiring ETH to complete the ritual.")}
                    </div>
                    <div className={classes["roadmap-right-align"]}>
                        {this.content("The Great Departure", "The launch of the SUWA DAO", "The SUWA governance will start, with the launch of guilds to delegate voting power and empower summoner parties. Take part in monthly ethereal calls with the Elders to help make key decisions related to the SUWA world-building, content creations and key partnerships.")}
                    </div>
                    <div className={classes["roadmap-left-align"]}>
                        {this.content("The Summoning", "Launch of Play-2-Earn", "The monster summoning will launch… Summon, merge and fight with your familiar against shadow creatures to level-up and be ready for the ultimate battle.")}
                    </div>
                    <div className={classes["roadmap-right-align"]}>
                        {this.content("The War", "Enter into the Metaverse", "The alpha of SUWA will become available in closed access for all SUWA holders. Give feedback and become the very first users to explore the world of Suwa.")}
                    </div>
                    <div className={classes["roadmap-left-align"]}>
                        {this.content("The MERGE", "Partnerships", "Partnerships with other metaverse platforms will allow users to bring their summoner across multiple realms… Stay tuned for more details.")}
                    </div>
                </div>
            </div>
		);
	}

    private content(title:string, legend:string, text:string): JSX.Element {
		return (
            <div className={classes["content-container"]}>
                <p className={classes["content-title"]}>{title}</p>
                <p className={classes["content-legend"]}>{legend}</p>
                <p className={classes["content-text"]}>{text}</p>
            </div>
        );
    }

}
