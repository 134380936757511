import React from "react";
import { Parallax } from "react-scroll-parallax";

import BlueBar from "assets/images/pages/home/crystal-blue-bar.png";
import BlueText from "assets/images/pages/home/crystal-blue-text.png";
import Text from "Components/Elements/Text";
import Title from "Components/Elements/Title";

import classes from "../classes.module.scss";

type IProps = {};
type IState = {};

export default class SummoningCrystal extends React.Component<IProps, IState> {
	public render(): JSX.Element {
		return (
			<div className={classes["summoning-crystal-section"]}>
				<div className={classes["summoning-crystal-container"]}>
					<div className={classes["summoning-crystal-tribes-content"]}>
						<Parallax speed={-15} style={{ position: "absolute", zIndex: "-1" }}>
							<img className={classes["summoning-crystal-blue-bar"]} src={BlueBar} alt="" />
						</Parallax>
						<Parallax speed={15} style={{ position: "absolute", zIndex: "-1" }}>
							<img className={classes["summoning-crystal-blue-text"]} src={BlueText} alt="" />
						</Parallax>
						<Title>The summoning crystal</Title>
						<Text>
							The summoning crystal contains imaginary creatures with extraordinary abilities. The crystal
							design reveals the level of rarity of the creature that lays dormant. When the time comes,
							these creatures will emerge from their crystals and unleash l their true strength…
						</Text>
					</div>
				</div>
			</div>
		);
	}
}
