import BurgerCross from "Components/Elements/BurgerCross";
import React from "react";

import SideMenuStatus, { EOpeningState } from "../../../Stores/SideMenuStatus";
import classes from "./classes.module.scss";
type IState = {
	status: EOpeningState;
};
type IProps = {};

export default class MenuButton extends React.Component<IProps, IState> {
	private removeOnSwitch = () => {};

	constructor(props: IProps) {
		super(props);
		this.state = {
			status: SideMenuStatus.getInstance().status,
		};
	}

	public render(): JSX.Element {
		return (
			<span onClick={this.toggleMenu} className={classes["root"]}>
				<BurgerCross state={this.state.status === EOpeningState.CLOSED ? "burger" : "cross"} />
				<p className={classes["menu-text"]}>Menu</p>
			</span>
		);
	}

	public componentDidMount() {
		this.removeOnSwitch = SideMenuStatus.getInstance().onSwitch((type) => {
			this.updateSideMenuStatus();
		});
	}

	public componentWillUnmount() {
		this.removeOnSwitch();
	}

	public toggleMenu() {
		SideMenuStatus.getInstance().toggle();
	}

	public getStatus() {
		return this.state.status;
	}

	private updateSideMenuStatus() {
		this.setState({
			status: SideMenuStatus.getInstance().status,
		});
	}
}

