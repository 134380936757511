import React from "react";

import { ReactComponent as HomeLogo } from "assets/images/pages/home/home-logo.svg";
import Text from "Components/Elements/Text";

import classes from "../classes.module.scss";

type IProps = {};
type IState = {};

export default class LandingPageSection extends React.Component<IProps, IState> {
    public render(): JSX.Element {
        return (
            <div className={classes["jumbotron"]}>
                <video
                    key={"home"}
                    autoPlay
                    loop
                    muted
                    playsInline
                    preload="auto"
                    poster={"/videos/home.png"}
                >
                    <source src={"/videos/home.mkv"} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className={classes["bg-home-mobile"]} />


                <div className={classes["home-text"]}>
                    <HomeLogo className={classes["home-logo"]} height="auto" />
                    <Text>
                        Suwa is a collection of 5000 generative portraits with meticulously designed
                        one-of-a-kind features. It symbolizes and embraces the spirit of summoner warriors
                        destined to protect the world from evil forces. This collection is the artistic
                        brainchild of Ble Ble, a talented Thai artist.
                    </Text>
                </div>
            </div>
        );
    }

}
