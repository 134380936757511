import classes from "./classes.module.scss";
type IProps = {
	state: "burger" | "cross";
};
export default function BurgerCross({ state }: IProps) {
	return (
		<svg
			data-state={state}
			className={classes["burger"]}
			width="48"
			height="14"
			viewBox="0 0 48 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M0 0V2.60465H48V0H0Z" fill="white" />
			<path d="M0 11V13.6047H48V11H0Z" fill="white" />
		</svg>
	);
}

