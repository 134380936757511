import React from "react";
import classes from "./classes.module.scss";

type IProps = {
	title: string
	subtitle: string
    children: any
}
export default class RoadmapParagraph extends React.Component<IProps> {
	public render(): JSX.Element {
		return (
			<div className={classes["root"]}>
                <div className={classes["paragraph-title"]}>
                    {this.props.title}
                </div>
                <div className={classes["paragraph-subtitle"]}>
                    {this.props.subtitle}
                </div>
                <br/>
                <div className={classes["paragraph-content"]}>
                    {this.props.children}
                </div>
            </div>
		);
	}
}
