import React from "react";
import { Link } from "react-router-dom";

import SideMenuStatus, { EOpeningState } from "Stores/SideMenuStatus";

import classes from "./classes.module.scss";

type IState = {
	status: EOpeningState;
};

type IProps = {};
export default class SideMenu extends React.Component<IProps, IState> {
	private removeOnSwitch = () => { };

	public constructor(props: IProps) {
		super(props);
		this.updateSideMenuStatus();
		this.state = {
			status: SideMenuStatus.getInstance().status,
		};
	}

	public render(): JSX.Element {
		return (
			<div className={classes["root"]} {...this.state}>
				<div className={classes["nav"]}>
					<svg
						className={classes["nav-separator"]}
						width="2"
						height="69"
						viewBox="0 0 2 69"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M1 1V68.5" stroke="white" strokeLinecap="round" />
					</svg>
					<nav className={classes["side-menu-nav"]}>
						<Link to="/story">The Story</Link>
						<Link to="/team">Team</Link>
						<Link to="/roadmap">Roadmap</Link>
					</nav>
				</div>
			</div>
		);
	}

	private updateSideMenuStatus() {
		this.setState({
			status: SideMenuStatus.getInstance().status,
		});
	}

	public componentDidMount() {
		this.removeOnSwitch = SideMenuStatus.getInstance().onSwitch((type) => {
			this.updateSideMenuStatus();
		});
	}

	public componentWillUnmount() {
		this.removeOnSwitch();
	}
}


