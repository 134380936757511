import React from "react";
import { Link } from "react-router-dom";

import Logo from "Components/Materials/Logo";

import MenuButton from "../MenuButton";
import SideMenu from "../SideMenu";
import classes from "./classes.module.scss";

type IState = {};
type IProps = {};
export default class Header extends React.Component<IProps, IState> {
	public render(): JSX.Element {
		return (
			<header className={classes["root"]}>
				<div className={classes["squares-container"]}>
					<div className={classes["square-left"]}>
						<Link className={classes["logo"]} to="/">
							<Logo />
						</Link>
					</div>
					<div className={classes["square-right"]}>
						<MenuButton />
					</div>
				</div>
				<SideMenu />
			</header>
		);
	}
}

