import SuwaDesktop from 'assets/images/pages/home/suwa_desktop.png'
import SuwaLargeDesktop from 'assets/images/pages/home/suwa_large_desktop.png'
import SuwaMobile from 'assets/images/pages/home/suwa_mobile.png'
import SuwaTablet from 'assets/images/pages/home/suwa_tablet.png'
import TopFooterImage from "assets/images/pages/home/top-footer-image.png"
import Text from "Components/Elements/Text";
import DropdownContainer, { IDropdownContent } from "Components/Materials/DropdownContainer";
import Desktop from "Components/Materials/UserAgent/Desktop";
import LargeDesktop from "Components/Materials/UserAgent/LargeDesktop";
import Mobile from "Components/Materials/UserAgent/Mobile";
import Tablet from "Components/Materials/UserAgent/Tablet";
import BasePage from "Components/Pages/Base";
import EightSummoneTtribesSection from "Components/Pages/Home/Sections/EightSummoneTribesSection";
import LandingPageSection from "Components/Pages/Home/Sections/LandingPageSection";
import RoadmapSection from "Components/Pages/Home/Sections/RoadmapSection";
import StartJourneySection from "Components/Pages/Home/Sections/StartJourneySection";
import TeamSection from "Components/Pages/Home/Sections/TeamSection";
import DefaultTemplate from "Components/PageTemplates/DefaultTemplate";

import classes from "./classes.module.scss";

export default class Home extends BasePage {

	public render(): JSX.Element {
		const faqData: IDropdownContent[] = [
			{
				title: "Why are we makings NFTs (Non-fungible tokens)?",
				content: <Text>Non-fungible tokens (NFTs) are a great proof of ownership for digital art. We want to use this technology to its fullest potential and create a long-term adventure for Suwa, living forever on the Ethereum blockchain.</Text>
			},
			{
				title: "What's the mint date?",
				content: <Text>The minting date will be revealed soon.</Text>
			},
			{
				title: "What is the mint price going to be?",
				content: <Text>You will know soon enough.</Text>
			},
			{
				title: "How many SUWA can I mint?",
				content: <Text>A whitelist will be set up, to allow dedicated members to buy at least one SUWA without the hurdle of high gas fees. However, once the Public-sale opens, the minting quantity will not be capped.</Text>
			},
			{
				title: "How do I know if I'm on the whitelist? ",
				content: <Text>If you’ve won a contest or a giveaway, your ETH address will be asked by an official member of the team.</Text>
			},
			{
				title: "How do I get a spot on the whitelist?",
				content: <Text>Follow our official Twitter account for regular contests and giveaways. We're also partnering with other great communities for WL giveaways.</Text>
			},
			{
				title: "What can I do with my SUWA?",
				content: <Text>Your Suwa NFT can be traded on every Ethereum NFT marketplace(OpenSea, LooksRare, Rarible, x2y2,...). However, stay tuned for the summoning phase.</Text>
			},
			{
				title: "What does Suwa mean?",
				content: <Text>Suwa is the contraction of Summoner Warriors.</Text>
			},
			{
				title: "Where can I check out BleBle’s previous work? ",
				content: bleblePreviousWork()
			},
		]
		return (
			<DefaultTemplate>
				<div className={classes["root"]}>
					<LandingPageSection />
					<StartJourneySection />
					<EightSummoneTtribesSection />
					<RoadmapSection />
					<LargeDesktop>
						<img src={SuwaLargeDesktop} alt="" className={classes["suwa-girls"]} />
					</LargeDesktop>
					<Desktop>
						<img src={SuwaDesktop} alt="" className={classes["suwa-girls"]} />
					</Desktop>
					<Tablet>
						<img src={SuwaTablet} alt="" className={classes["suwa-girls"]} />
					</Tablet>
					<Mobile>
						<img src={SuwaMobile} alt="" className={classes["suwa-girls"]} />
					</Mobile>
					<TeamSection withButton={true}/>
					<DropdownContainer dropdownContent={faqData} />
					<img alt="footer-top" src={TopFooterImage} className={classes["top-footer-img"]} />
				</div>
			</DefaultTemplate>
		);

		function bleblePreviousWork() {
			return (
				<div className={classes["previous-work"]}>
					<Text>
						You can check out all BleBle's artworks right there:
						<br />
						<div className={classes["previous-work-links"]}>
							<div className={classes["previous-work-container"]}>
								<div className={classes["previous-work-left"]}>Instagram</div>
								<div className={classes["previous-work-right"]}>
									<a href="https://www.instagram.com/bleble_158/" target="_blank" rel="noreferrer">
										@bleble_158
									</a>
								</div>
							</div>
							<div className={classes["previous-work-container"]}>
								<div className={classes["previous-work-left"]}>Artstation</div>
								<div className={classes["previous-work-right"]}>
									<a href="https://www.artstation.com/bleble" target="_blank" rel="noreferrer">
										@bleble
									</a>
								</div>
							</div>
							<div className={classes["previous-work-container"]}>
								<div className={classes["previous-work-left"]}>Eyesfi</div>
								<div className={classes["previous-work-right"]}>
									<a
										href="https://www.eyesfi.com/gallery/BleBle158/3d/1314962"
										target="_blank"
										rel="noreferrer"
									>
										@BleBle58
									</a>
								</div>
							</div>
						</div>
					</Text>
				</div>
			);
		}
	}
}
