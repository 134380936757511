import CloudsLeftDesktop from "assets/images/pages/roadmap/clouds-left-desktop.png";
import CloudsLeftLargeDesktop from "assets/images/pages/roadmap/clouds-left-large-desktop.png";
import CloudsLeftMobile from "assets/images/pages/roadmap/clouds-left-mobile.png";
import CloudsLeftTablet from "assets/images/pages/roadmap/clouds-left-tablet.png";
import CloudsRightDesktop from "assets/images/pages/roadmap/clouds-right-desktop.png";
import CloudsRightLargeDesktop from "assets/images/pages/roadmap/clouds-right-large-desktop.png";
import CloudsRightMobile from "assets/images/pages/roadmap/clouds-right-mobile.png";
import CloudsRightTablet from "assets/images/pages/roadmap/clouds-right-tablet.png";
import BigTitle from "Components/Elements/BigTitle";
//import Bold from "Components/Elements/Bold";
import Text from "Components/Elements/Text";
import Desktop from "Components/Materials/UserAgent/Desktop";
import LargeDesktop from "Components/Materials/UserAgent/LargeDesktop";
import Mobile from "Components/Materials/UserAgent/Mobile";
import Tablet from "Components/Materials/UserAgent/Tablet";
import DefaultTemplate from "Components/PageTemplates/DefaultTemplate";

import BasePage from "../Base";
import classes from "./classes.module.scss";
import Characters from "./images/characters.png";
import FirstShape from "./images/first_shapes.png";
import LeftPortalMobile from "./images/left_portal_mobile.png";
import LeftPortal from "./images/left_portal.png";
import RightGirls from "./images/right_girls.png";
import SecondShape from "./images/second_shapes.png";
import RoadmapParagraph from "./roadmap-paragraph";

type IProps = {}
type IState = {
	scroll : number;
}
export default class Roadmap extends BasePage<IProps,IState> {

	constructor(props:any){
		super(props);
		
		this.state = {
			scroll: 0
		}

		this.handleScroll = this.handleScroll.bind(this);
	}
	public render(): JSX.Element {
		return (
			<DefaultTemplate>
				<div className={classes["root"]}>
					<div className={classes["clouds-container"]}>
						<LargeDesktop>
							<img
								src={CloudsLeftLargeDesktop}
								className={classes["clouds-left"]}
								alt=""
								style={{ transform: `translate(-${this.state.scroll}px,-${this.state.scroll}px)` }}
							/>
							<img
								src={CloudsRightLargeDesktop}
								className={classes["clouds-right"]}
								alt=""
								style={{ transform: `translate(${this.state.scroll}px,-${this.state.scroll}px)` }}
							/>
						</LargeDesktop>
						<Desktop>
							<img
								src={CloudsLeftDesktop}
								className={classes["clouds-left"]}
								alt=""
								style={{ transform: `translate(-${this.state.scroll}px,-${this.state.scroll}px)` }}
							/>
							<img
								src={CloudsRightDesktop}
								className={classes["clouds-right"]}
								alt=""
								style={{ transform: `translate(${this.state.scroll}px,-${this.state.scroll}px)` }}
							/>
						</Desktop>
						<Tablet>
							<img
								src={CloudsLeftTablet}
								className={classes["clouds-left"]}
								alt=""
								style={{ transform: `translate(-${this.state.scroll}px,-${this.state.scroll}px)` }}
							/>
							<img
								src={CloudsRightTablet}
								className={classes["clouds-right"]}
								alt=""
								style={{ transform: `translate(${this.state.scroll}px,-${this.state.scroll}px)` }}
							/>
						</Tablet>
						<Mobile>
							<img
								src={CloudsLeftMobile}
								className={classes["clouds-left"]}
								alt=""
								style={{ transform: `translate(-${this.state.scroll}px,-${this.state.scroll}px)` }}
							/>
							<img
								src={CloudsRightMobile}
								className={classes["clouds-right"]}
								alt=""
								style={{ transform: `translate(${this.state.scroll}px,-${this.state.scroll}px)` }}
							/>
						</Mobile>
					</div>
					<BigTitle subtitle="ロードマップ">ROADMAP</BigTitle>
					<div className={classes["first-line-container"]}>
						<Text>Let's build the SUWA world together</Text>
					</div>

					<div className={classes["roadmap-content-container"]}>
						<div className={classes["first-part-container"]}>
							<img
								src={Characters}
								alt="ロードマップ written in background"
								className={classes["characters-background"]}
							/>
							<div className={classes["first-part-txt"]}>
								<img
									src={FirstShape}
									alt="Shapes in the background"
									className={classes["first-shape"]}
								/>
								<RoadmapParagraph title="The Gathering" subtitle="Public-sale">
									New heroes have gathered in preparation for the upcoming battle. A celestial seal is
									being set up to bring forth energy to every chosen ones’ summoning crystal. Once
									this ceremony is ready, the incantation will require the sacrifice of Ether energy
									to complete the ritual.
								</RoadmapParagraph>
								<RoadmapParagraph title="The Great Departure" subtitle="The launch of the SUWA DAO">
									Our heroes are now ready to start their journey… The future of the world lies in
									their hands and every decision will count… Our summoners empowered with their new
									powers will use it to fight dark forces. Under the guidance of the Celestial Beings,
									these Heroes shall decide and engage in training and make good use of their powers.
									Talented individuals are welcome to continue developing the summoner’s governance or
									create its own guild to adventure in the world of X. But beware, time is running out
									before the dawn of the battle.
								</RoadmapParagraph>
							</div>
							<div className={classes["first-part-girls"]}>
								<img src={RightGirls} alt="Two suwa girls standing" />
							</div>
						</div>
						<div className={classes["second-part-container"]}>
							<div className={classes["second-part-portal"]}>
								<img src={LeftPortal} alt="" className={classes["left-portal"]} />
								<img src={LeftPortalMobile} alt="" className={classes["left-portal-mobile"]} />
							</div>
							<div className={classes["second-part-txt"]}>
								<img
									src={SecondShape}
									alt="Shapes in the background"
									className={classes["second-shape"]}
								/>
								<RoadmapParagraph title="The Summoning" subtitle="Launch of Play-2-Earn">
									During a night of full moon, all summoners’ crystals start to make strange signals…
									Is it time already for the summoning…?
								</RoadmapParagraph>
								<div className={classes["left-padding-container"]}>
									<RoadmapParagraph title="The War" subtitle="Enter into the Metaverse">
										A portal to the metaverse has opened for our summoners… Now it’s time to engage
										in the Great Battle, accompanied by your tamed familiars…
									</RoadmapParagraph>
								</div>
								<RoadmapParagraph title="The Merge" subtitle="Partnerships">
									Something unexpected is happening on the battlefield… What could that light be…?
								</RoadmapParagraph>
							</div>
						</div>
					</div>
				</div>
			</DefaultTemplate>
		);
	}

	public componentDidMount() {
		window.addEventListener("scroll", this.handleScroll);
	}

	public componentWillUnmount() {
		window.removeEventListener("scroll", this.handleScroll);
	}

	public handleScroll() {
		this.setState({
			scroll: window.scrollY,
		});
	}
}
