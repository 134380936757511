import IceBlock from "assets/images/pages/story/ice_block.png";
import BigTitle from "Components/Elements/BigTitle";
import Bold from "Components/Elements/Bold";
import Text from "Components/Elements/Text";
import Title from "Components/Elements/Title";
import DefaultTemplate from "Components/PageTemplates/DefaultTemplate";

import BasePage from "../Base";
import classes from "./classes.module.scss";
import LeftIcons from './images/left_icons.png';
import RightShape from './images/right_shape.png';
import SummonersMobile from './images/summoners_mobile.png';

export default class Story extends BasePage {
	public render(): JSX.Element {
		return (
			<DefaultTemplate>
				<div className={classes["root"]}>
					<BigTitle subtitle="物語" color="2">
						THE STORY
					</BigTitle>
					<div className={classes["ice-block-container"]}>
						<img src={IceBlock} alt="" className={classes["ice-block-img"]} />
					</div>
					<div className={classes["story-content"]}>
						<Title>A legend told for generations</Title>
						<div className={classes["text-container"]}>
							<Text>
								1000 years ago, the <Bold>world of Suwa</Bold> was at peace…
								<br />
								<br />
								The land was ruled by 8 distinct tribes, living together in harmony and bringing balance
								to its world… Humans, Elves, Dragons, Demons, Beasts, Yokais, Mermaids and Flames…
								<br />
								<br />
								Above them all, were The Elders, mysterious Celestial Beings who used their supreme
								forces to bring order between these tribes…
								<br />
								<br /> However, one Elder became greedy and decided to use its supreme power to
								influence the <Bold>world of SUWA</Bold> for its own purpose.
								<br />
								<br /> The Elders, believing in absolute equilibrium, decided to banish the selfish
								celestial being for acting this way.
								<br />
								<br />
								Sadness, disbelief, and then hatred for those he previously cared so much….{" "}
								<Bold>“I will forge a new world on my own”</Bold> he said to himself… Thus, the newly
								borned shadow spawned evil creatures to the <Bold>world of SUWA</Bold> and started the
								great Cataclysm…
								<br />
								<br />
								Faced to this critical situation, the remaining <Bold>Celestial Beings</Bold> brought
								forth <Bold>5000 individuals</Bold> to become the chosen <Bold>Summoner Warriors</Bold>.
								<br />
								<br />
								With this new power, the <Bold>Summoner Warriors</Bold> fought the Shadow creatures
								until they became victorious.
								<br />
								<br />
								However, this story is far from over, this is just the beginning…
							</Text>
							<img src={LeftIcons} alt="" className={classes["left-icons"]} />
						</div>
					</div>
					<img src={SummonersMobile} alt="" className={classes["summoners-mobile"]} />
					<img src={RightShape} alt="" className={classes["right-shape"]} />
				</div>
			</DefaultTemplate>
		);
	}
}












