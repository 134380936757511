import { BigNumber, ethers } from "ethers";

export default class EthBigNumber {
	constructor(protected readonly bigNumber: BigNumber) {}

	public formatUnits(unitName: ethers.BigNumberish = 18) {
		return ethers.utils.formatUnits(this.bigNumber, unitName);
	}

	public getBigNumber() {
		return this.bigNumber;
	}

	public static from(value: string) {
		return new this(BigNumber.from(value));
	}
}

